import { useEffect, useState } from "react";
import { RequestWrapper } from "../Styles/Movie-Style";
import axios from "axios";
import { Fetch_Active_Screens_URL, Request_Movies_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';



function RequestMovie() {


    const [reload, setReload] = useState(false);
    const [forms, setForms] = useState([{ theaterName: '', movieName: '' }]);
    const [theater, setTheater] = useState('');


    useEffect(() => {

        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Active_Screens_URL}?user=${user}`)
        .then(res => {
            console.log(res);
            setTheater(res.data.theater);
            setForms([{ theaterName: res.data.theater, movieName: '' }]);
        })
        .catch(err => {
            // console.log(err);
        })

    }, [reload]);

    const handleInputChange = (index, event) => {
        const newForms = [...forms];
        newForms[index].movieName = event.target.value;
        setForms(newForms);
    };

    const addForm = () => {
        setForms([...forms, { theaterName: theater, movieName: '' }]);
    };

    const removeForm = (index) => {
        const newForms = forms.filter((_, i) => i !== index);
        setForms(newForms);
    };

    const handleSubmitRequest = (e) => {
        e.preventDefault();

        axios.post(Request_Movies_URL, forms, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
        })
        .catch(err => {
            console.log(err);
        })
    };

    return(
        <>
            <RequestWrapper>
                <div className="header">
                    <h3>If you can't find your movie. Please make a request with the movie name.</h3>
                    <a onClick={addForm}><i className="fa-solid fa-plus"></i>Add</a>
                </div>
                <div className="form_part">
                    <div className="part_inner">
                        <form onSubmit={handleSubmitRequest}>
                            <div className="form_inner">
                                {forms.map((form, index) => (
                                    <div className="form_box">
                                        <div className="input_box">
                                            <input type="text" value={form.movieName} onChange={(e) => handleInputChange(index, e)} required />
                                            <span>Movie Name</span>
                                        </div>
                                        <div className="delete_btn">
                                            <a onClick={() => removeForm(index)}><i className="fa-solid fa-trash-can"></i></a>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="form_btn"><button>Submit</button></div>
                        </form>
                    </div>
                </div>
            </RequestWrapper>
        </>
    );
}


export default RequestMovie;