import { useEffect, useState } from "react";
import { ScreenBox } from "../Styles/Screens-Style";
import axios from "axios";
import { Fetch_Screen_Shows_URL } from "../API/Api";




function Screen({selectedScreen}) {


    const [count, setCount] = useState(false);
    const [movies, setMovies] = useState([]);

    useEffect(() => {

        axios.get(`${Fetch_Screen_Shows_URL}?id=${selectedScreen.screen_id}`)
        .then(res => {
            console.log(res);
            const moviesWithFormattedDate = res.data.shows.map(movie => ({
                ...movie,
                formatted_date: movie.date.split(',')[0] // Use the backend formatted_date or extract it here
            }));
            setCount(true);
            setMovies(moviesWithFormattedDate);
        })
        .catch(err => {
            // console.log(err);
            setCount(false);
        })

    }, [selectedScreen]);

    return(
        <>
            <ScreenBox>
                <div className="table_sec">
                    <table>
                        <thead>
                            <th>Sl. No.</th>
                            <th>Movie Name</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Ticket Booked</th>
                            <th>Action</th>
                        </thead>
                        <tbody>
                            {
                                count ?
                                movies.map((movie, index) => 
                                <tr>
                                    <td>{index + 1}.</td>
                                    <td>{movie.movie_name}</td>
                                    <td>{movie.formatted_date}</td>
                                    <td>{movie.time}</td>
                                    <td>{movie.total_booking}</td>
                                    <td>
                                        <span className="view"><i class="fa-solid fa-eye"></i></span>
                                    </td>
                                </tr>
                                )
                                :
                                <tr>
                                    <p>No Movies Found</p>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </ScreenBox>
        </>
    );
}


export default Screen;