import { useEffect, useState } from "react";
import { MovieWrapper } from "../Styles/Movie-Style";
import { Link } from "react-router-dom";
import axios from "axios";
import { Fetch_Today_Shows_URL } from "../API/Api";



function TodayShowing() {

    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [filterType, setFilterType] = useState('Select');
    const filterTypes = ['Movie Name', 'Language'];
    const [count, setCount] = useState(false);
    const [movies, setMovies] = useState([]);
    const [reload, setReload] = useState(false);
    const [filteredMovies, setFilteredMovies] = useState([]);
    const [searchInput, setSearchInput] = useState('');


    function handleFilterDropdown() {
        setShowFilterDropdown(!showFilterDropdown);
    }

    useEffect(() => {

        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Today_Shows_URL}?user=${user}`)
        .then(res => {
            console.log(res);
            setCount(true);
            setMovies(res.data.movies);
            setFilteredMovies(res.data.movies);
            setReload(false);
        })
        .catch(err => {
            console.log(err);
            setCount(false);
        })
        
    }, [reload]);

    useEffect(() => {
        handleSearch();
    }, [searchInput, filterType]);

    const handleSearch = () => {
        if (filterType === 'Select' || searchInput.trim() === '') {
            setFilteredMovies(movies);
            return;
        } 
        
        const filtered = movies.filter(movie => {
            if (filterType === 'Movie Name') {
                return movie.movie_name.toLowerCase().includes(searchInput.toLowerCase());
            } else if (filterType === 'Language') {
                return movie.language.toLowerCase().includes(searchInput.toLowerCase());
            } else {
                return false;
            }
        });

        setFilteredMovies(filtered);
        setCount(filtered.length > 0);
    };

    return(
        <>
            <MovieWrapper>
                <div className="filter_search_sec">
                    <div className="filter_box">
                        <h5>Search By:</h5>
                        <div className="select_box">
                            <input type="text" id="filter" name="filter" required />
                            <div className="dropdown_btn" onClick={handleFilterDropdown}>
                                <p>{filterType}</p>
                                <i class={`fa-solid fa-angle-down ${showFilterDropdown? 'active':''}`}></i>
                            </div>
                            <div className={`dropdown ${showFilterDropdown? 'active':''}`}>
                                <ul>
                                    {
                                        filterTypes.map(filterType => (
                                            <li onClick={(e) => {
                                                setFilterType(filterType)
                                                setShowFilterDropdown(false)
                                                document.getElementById('filter').value = filterType
                                                document.getElementById('filter').click()
                                            }}>{filterType}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="search_box">
                        <h5>Search:</h5>
                        <input type="text" placeholder="Enter Search Input" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} name="search" required />
                    </div>
                    <div className="add_btn">
                        <a href="#"><i className="fa-solid fa-plus"></i>Add Movie</a>
                    </div>
                </div>
                <div className="table_sec">
                    <div className="table_inner">
                        <table>
                            <thead>
                                <th>Sl. No.</th>
                                <th>Movie Name</th>
                                <th>Language</th>
                                <th>Screen</th>
                                <th>Time</th>
                                <th>Details</th>
                            </thead>
                            <tbody>
                                
                                {
                                    count ? 
                                    (filteredMovies  && filteredMovies.map((movie, index) => 
                                        <tr key={index}>
                                            <td>{index + 1}.</td>
                                            <td>{movie.movie_name}</td>
                                            <td>{movie.language}</td>
                                            <td>{movie.screen}</td>
                                            <td>{movie.time}</td>
                                            <td>
                                                <span className="view"><i class="fa-solid fa-eye"></i></span>
                                            </td>
                                        </tr>)) 
                                    :
                                    <tr>
                                        <p>No Movies Found</p>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </MovieWrapper>
        </>
    );
}


export default TodayShowing;