import { ConfirmModalBox } from "../Styles/Modal-Styles";




function ModalConfirm({confirmModalShow, setConfirmModalShow, refreshBookedSeats}) {

    const handleConfirm = () => {
        setConfirmModalShow(false);
        refreshBookedSeats();
    };

    return(
        <>
            <ConfirmModalBox className={confirmModalShow ? 'active' : ''}>
                <div className={`modal_box ${confirmModalShow ? 'active' : ''}`}>
                    <div className="box_inner">
                        <div className="icon">
                            <img src="/images/success.gif" alt="icon" />
                        </div>
                        <div className="message">
                            <p>Booking confirmed.</p>
                        </div>
                        <div className="button">
                            <a onClick={handleConfirm}><span>Ok</span></a>
                        </div>
                    </div>
                </div>
            </ConfirmModalBox>
        </>
    );
}


export default ModalConfirm;