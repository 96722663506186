import { useState } from "react";
import { ReviewWrapper } from "../Styles/Movie-Style";




function Review() {

    const [showFilterDropdown, setShowFilterDropdown] = useState(false);
    const [filterType, setFilterType] = useState('Select');
    const filterTypes = ['Movie Name', 'Type', 'Activity'];


    function handleFilterDropdown() {
        setShowFilterDropdown(!showFilterDropdown);
    }

    return(
        <>
            <ReviewWrapper>
                <div className="filter_search_sec">
                    <div className="filter_box">
                        <h5>Search By:</h5>
                        <div className="select_box">
                            <input type="text" id="filter" name="filter" required />
                            <div className="dropdown_btn" onClick={handleFilterDropdown}>
                                <p>{filterType}</p>
                                <i class={`fa-solid fa-angle-down ${showFilterDropdown? 'active':''}`}></i>
                            </div>
                            <div className={`dropdown ${showFilterDropdown? 'active':''}`}>
                                <ul>
                                    {
                                        filterTypes.map(filterType => (
                                            <li onClick={(e) => {
                                                setFilterType(filterType)
                                                setShowFilterDropdown(false)
                                                document.getElementById('filter').value = filterType
                                                document.getElementById('filter').click()
                                            }}>{filterType}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="search_box">
                        <h5>Search:</h5>
                        <input type="text" placeholder="Enter Search Input" name="search" required />
                    </div>
                </div>
                <div className="table_sec">
                    <div className="table_inner">
                        <table>
                            <thead>
                                <th>Sl. No.</th>
                                <th>User</th>
                                <th>Movie Name</th>
                                <th>Rating</th>
                                <th>Comments</th>
                                <th>Action</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1.</td>
                                    <td>Sourish Mondal</td>
                                    <td>Avatar: The Way of Water</td>
                                    <td>4 / 5</td>
                                    <td><p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores neque odit illo culpa tempora aliquid temporibus dolorum natus? Optio, modi cum cupiditate nesciunt odit molestias!</p></td>
                                    <td>
                                        <span className="view"><i class="fa-solid fa-eye"></i></span>
                                        <span className="delete"><i class="fa-solid fa-trash"></i></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </ReviewWrapper>
        </>
    );
}


export default Review;