import { useState } from "react";
import ReactApexChart from 'react-apexcharts';



function TicketData() {

    const [series, setSeries] = useState([
        { name: 'Online', data: 80 },
        { name: 'Offline', data: 25 }
    ]);

    const [options, setOptions] = useState({
        chart: {
            width: 450,
            type: 'donut',
            toolbar: {
                show: false
            }
        },
        dataLabels: {
            enabled: false,
        },
        fill: {
            type: 'gradient',
        },
        legend: {
            position: 'right',
            offsetX: 5,
            offsetY: 0,
            height: 230,
        },
        colors: ['#02C0FF', '#FF33A1'],
        labels: series.map(item => item.name),
    });

    return(
        <>
            <div id="chart">
                <ReactApexChart  options={options} series={series.map(item => item.data)} type="donut" width={450} />
            </div>
            <div id="html-dist"></div>
        </>
    );
}


export default TicketData;