import { styled } from "@mui/material";


export const ScreensWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 15px;

    .screen_details {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .top_sec {
            position: relative;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .screen_select_sec {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                z-index: 50;

                .icon {
                    position: relative;
                    width: 75px;
                    height: 75px;
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .dropdown_sec {
                    position: relative;
                    margin-left: 25px;

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 270px;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.8s;
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 2px 0;
                                padding: 13px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }

            .box {
                position: relative;
                margin-left: 35px;
                width: max-content;
                height: 100px;
                border-radius: 10px;
                padding: 25px 30px;
                transition: all 0.5s;
                text-decoration: none;
                background: linear-gradient(to bottom, #fafafa 00%, rgba(63, 228, 79, 0.7) 100%);

                .box_inner {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: flex-end;

                    .inner_item {
                        position: relative;
                        display: flex;
                        flex-direction: column;

                        h4 {
                            position: relative;
                            font-family: 'Lemonada', cursive;
                            font-size: 18px;
                            font-weight: 600;
                            color: #222;
                        }

                        p {
                            position: relative;
                            font-family: 'Poppins', sans-serif;
                            line-height: 1;
                            font-size: 17px;
                            margin-top: 7px;
                            font-weight: 500;
                            color: #444;
                        }
                    }

                    .icon {
                        position: relative;
                        width: 70px;
                        display: flex;
                        justify-content: flex-end;

                        i {
                            position: relative;
                            font-size: 40px;
                            color: #333;
                        }
                    }
                }
            }
        }

        .details_sec {
            position: relative;
            width: 100%;
            height: calc(100% - 100px);
            padding-top: 50px;
        }
    }

`;

export const ScreenBox = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;

    .table_sec {
        position: relative;
        width: 100%;
        height: 100%;

        table {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            thead {
                position: relative;
                width: 100%;
                height: 55px;
                display: flex;
                background: #FC6736;
                border-radius: 10px 10px 0 0 ;
                border: 1px solid #FC6736;

                th {
                    position: relative;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    font-family: 'Lemonada', cursive;
                    font-size: 14px;
                    color: #FFF;
                    font-weight: 600;
                    padding: 0px 25px;

                    &:nth-child(1) {
                        width: 7%;
                    }

                    &:nth-child(2) {
                        width: 33%;
                    }

                    &:nth-child(3) {
                        width: 15%;
                        justify-content: center;
                    }

                    &:nth-child(4) {
                        width: 12%;
                        justify-content: center;
                    }

                    &:nth-child(5) {
                        width: 20%;
                        justify-content: center;
                    }

                    &:nth-child(6) {
                        width: 13%;
                        justify-content: center;
                    }
                }
            }

            tbody {
                position: relative;
                width: 100%;
                max-height: calc(100% - 55px);
                border: 1px solid #E1E0EA;
                border-radius: 0 0 10px 10px;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                scrollbar-width: none;

                tr {
                    position: relative;
                    width: 100%;
                    min-height: 55px;
                    display: flex;
                    border-bottom: 1px solid #E1E0EA;

                    p {
                        position: relative;
                        width: 100%;
                        padding: 15px 25px;
                        display: flex;
                        justify-content: center;
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        line-height: 1.5;
                    }

                    &:last-of-type {
                        border-bottom: none;
                    }

                    &:nth-of-type(even) {
                        background: rgba(2, 192, 255, 0.15);
                    }

                    td {
                        position: relative;
                        padding: 15px 25px;
                        display: flex;
                        font-family: 'Poppins', sans-serif;
                        font-size: 15px;
                        line-height: 1.5;

                        &:nth-child(1) {
                            width: 7%;
                        }

                        &:nth-child(2) {
                            width: 33%;
                        }

                        &:nth-child(3) {
                            width: 15%;
                            justify-content: center;
                        }

                        &:nth-child(4) {
                            width: 12%;
                            justify-content: center;
                        }

                        &:nth-child(5) {
                            width: 20%;
                            justify-content: center;
                        }

                        &:nth-child(6) {
                            width: 13%;
                            justify-content: center;

                            span {
                                position: relative;
                                cursor: pointer;
                                font-size: 16px;

                                &.view {
                                    color: #02C0FF;
                                }

                                &.delete {
                                    margin-right: 10px;
                                    color: #FF33A1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const ScreenSettingWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 20px;

    .message {
        position: relative;
        width: 100%;

        p {
            position: relative;
            font-size: 17px;
            color: #555;
            font-family: 'Lemonada', cursive;

            a {
                font-weight: 500;
                color: #02C0FF;
                text-decoration: none;
                cursor: pointer;
                font-family: 'Lemonada', cursive;
            }
        }
    }

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .top_message {
            position: relative;
            width: 100%;

            h4 {
                position: relative;
                font-size: 17px;
                font-weight: 500;
                color: #111;
                font-family: 'Lemonada', cursive;
            }
        }

        .inner_top {
            position: relative;
            margin-top: 40px;
            width: 100%;
            display: flex;
            align-items: center;

            .screen_select_sec {
                position: relative;
                display: flex;
                align-items: center;

                .icon {
                    position: relative;
                    width: 75px;
                    height: 75px;
                    overflow: hidden;
                    border-radius: 50%;

                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .dropdown_sec {
                    position: relative;
                    margin-left: 25px;

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 270px;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;

                        p {
                            position: relative;
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        i {
                            position: relative;
                            margin-left: auto;
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.8s;
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 2px 0;
                                padding: 13px 15px;
                                color: #555;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }

            .price_set_sec {
                position: relative;
                margin-left: auto;
                display: flex;
                align-items: center;

                .select_box {
                    position: relative;
                    margin-right: 30px;

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 230px;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        cursor: pointer;

                        p {
                            position: relative;
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                        }

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.8s;
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 2px 0;
                                padding: 13px 15px;
                                color: #555;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }

                .input_box {
                    position: relative;
                    margin-right: 30px;

                    input {
                        position: relative;
                        width: 230px;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        padding: 5px 25px;
                        outline: none;
                        font-size: 16px;
                        color: #333;
                        transition: 0.5s;

                        &:focus,
                        &:valid {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        transform: translateY(-50%);
                        transition: 0.5s;
                        pointer-events: none;
                    }

                    input:focus ~ span,
                    input:valid  ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .button_box {
                    position: relative;

                    a {
                        position: relative;
                        width: 170px;
                        height: 55px;
                        cursor: pointer;
                        font-size: 16px;
                        font-weight: 500;
                        overflow: hidden;
                        background: none;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 2px solid #3FE44F;

                        span {
                            position: relative;
                            z-index: 5;
                            color: #FFF;
                            transition: all 0.5s;

                            i {
                                font-size: 18px;
                                margin-right: 10px;
                            }
                        }

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #3FE44F;
                            transform: scaleX(1);
                            transform-origin: left;
                            transition: transform 0.5s;
                        }

                        &:hover:before {
                            transform: scaleX(0);
                            transform-origin: right;
                            transition: transform 0.5s;
                        }

                        &:hover span {
                            color: #3FE44F;
                            transition: all 0.4s;
                        }
                    }
                }
            }
        }

        .inner_content {
            position: relative;
            width: 100%;
            margin-top: 100px;
            display: flex;
            flex-wrap: wrap;

            .screen_price_box {
                position: relative;
                width: 25%;
                margin-top: 50px;
                padding-right: 30px;
                display: flex;
                flex-direction: column;

                h5 {
                    position: relative;
                    width: max-content;
                    font-family: 'Lemonada', cursive;
                    font-size: 18px;
                    font-weight: 600;
                    color: #333;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -3px;
                        width: 75%;
                        height: 3px;
                        background: #FC6736;
                        border-radius: 25px;
                    }
                }

                .price_box_sec {
                    position: relative;
                    margin-top: 25px;
                    display: flex;
                    flex-direction: column;

                    li {
                        position: relative;
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;

                        p {
                            position: relative;
                            font-size: 16px;
                            font-weight: 500;
                            color: #111;
                        }

                        span {
                            position: relative;
                            margin-left: 10px;
                            font-size: 16px;
                            font-weight: 600;
                            color: #555;
                        }
                    }
                }
            }
        }

        .inner_button {
            position: relative;
            margin-top: 50px;
            width: 100%;
            display: flex;
            justify-content: flex-end;

            a {
                position: relative;
                width: 225px;
                height: 55px;
                cursor: pointer;
                font-size: 16px;
                font-weight: 500;
                overflow: hidden;
                background: none;
                border-radius: 6px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #02C0FF;

                span {
                    position: relative;
                    z-index: 5;
                    color: #FFF;
                    transition: all 0.5s;

                    i {
                        font-size: 18px;
                        margin-right: 10px;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: #02C0FF;
                    transform: scaleX(1);
                    transform-origin: left;
                    transition: transform 0.5s;
                }

                &:hover:before {
                    transform: scaleX(0);
                    transform-origin: right;
                    transition: transform 0.5s;
                }

                &:hover span {
                    color: #02C0FF;
                    transition: all 0.4s;
                }
            }
        }
    }
`;