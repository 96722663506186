import { PriceModalBox } from "../Styles/Modal-Styles";




function ModalPrice({priceModalShow, setPriceModalShow, setConfirmModalShow, totalPrice}) {

    const handleConfirmBooking = () => {
        setPriceModalShow(false);
        setConfirmModalShow(true);
    };

    return(
        <>
            <PriceModalBox className={priceModalShow ? 'active' : ''}>
                <div className={`modal_box ${priceModalShow ? 'active' : ''}`}>
                    <div className="box_inner">
                        <div className="icon">
                            <img src="/images/Thank-you.gif" alt="icon" />
                        </div>
                        <div className="message">
                            <p>Please collect <span>Rs. {totalPrice}/-</span> to confirm this booking.</p>
                        </div>
                        <div className="button">
                            <a onClick={handleConfirmBooking}><span>Done</span></a>
                        </div>
                    </div>
                </div>
            </PriceModalBox>
        </>
    );
}


export default ModalPrice;