import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import { PanelLayout } from "../Styles/Layout-Style";
import { useEffect, useState } from "react";



function PaneLayout() {

    const navigate = useNavigate();

    
    useEffect(() => {
        
        let loginStatus = localStorage.getItem("LoggedIn");
        
        if(!loginStatus || loginStatus !== "true") {
            navigate('/');
        } 

    }, []);

    const [pageName, setPageName] = useState('Dashboard');
    

    return(
        <>
            <PanelLayout>
                <div className="left_side">
                    <Sidebar setPageName={setPageName} />
                </div>
                <div className="right_side">
                    <div className="top_bar">
                        <Navbar pageName={pageName} />
                    </div>
                    <div className="main_content">
                        <div className="content_inner">
                            <div className="inner_items">
                                <Outlet />
                            </div>
                        </div>
                    </div>
                </div>
            </PanelLayout>
        </>
    );
}


export default PaneLayout;