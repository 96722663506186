import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { Fetch_Booked_Seats_URL, Fetch_Screens_URL, Today_Screen_Shows_URL } from "../API/Api";
import { ScreenLayoutWrapper } from "../Styles/Layout-Style";
import SeatLayout from "../Components/SeatLayout";



function ScreenLayouts() {
    
    const [noRegeisterShow, setNoRegeisterShow] = useState(true);
    const [RegeisteredShow, setRegeisteredShow] = useState(false);
    const [screenDropdown, setScreenDropdown] = useState(false);
    const [moviesDropdown, setMoviesDropdown] = useState(false);
    const [screensShow, setScreensShow] = useState(false);
    const [theater, setTheater] = useState('');
    const [screens, setScreens] = useState([]);
    const [movies, setMovies] = useState([]);
    const [selectedScreen, setSelectedScreen] = useState(null);
    const [reload, setReload] = useState(false);
    const [checkPublish, setCheckPublish] = useState(false);
    const [selectedMovieName, setSelectedMovieName] = useState('');
    const [selectedMovie, setSelectedMovie] = useState([]);
    const [bookedSeats, setBookedSeats] = useState([]);

    useEffect(() => {
        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Screens_URL}?user=${user}`)
            .then(res => {
                let status = res.data.status;

                if (status == "202") {
                    setNoRegeisterShow(true);
                    setRegeisteredShow(false);
                } else if (status == "200") {
                    setNoRegeisterShow(false);
                    setRegeisteredShow(false);
                    setScreensShow(true);
                    setTheater(res.data.theater);
                    setSelectedScreen(res.data.screens[0]);
                    setScreens(res.data.screens);
                    setReload(false);
                }
            })
            .catch(err => {
                // console.log(err);
                setNoRegeisterShow(false);
                setRegeisteredShow(true);
            });
    }, [reload]);

    useEffect(() => {
        if (selectedScreen && selectedScreen.prices == null) {
            setCheckPublish(false);
        } else {
            setCheckPublish(true);
        }
    }, [selectedScreen]);

    useEffect(() => {
        if (selectedScreen) {
            axios.get(`${Today_Screen_Shows_URL}?id=${selectedScreen.screen_id}`)
                .then(res => {
                    // console.log(res.data);
                    setMovies(res.data.movies);
                    setSelectedMovie([]);
                    setSelectedMovieName('');
                    setBookedSeats([]);
                })
                .catch(err => {
                    // console.log(err);
                    setMovies();
                    setBookedSeats([]);
                });
        }
    }, [selectedScreen]);

    const handleScreenDropdown = () => {
        setScreenDropdown(!screenDropdown);
        setMoviesDropdown(false);
    };

    const handleMoviesDropdown = () => {
        setScreenDropdown(false);
        setMoviesDropdown(!moviesDropdown);
    };

    const handleRefresh = () => {
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 1000);
    };

    const handleBookedSeats = (movieName, date, time) => {

        const inputs = {
            theater: theater,
            screen: selectedScreen.screen,
            screen_id: selectedScreen.screen_id,
            movie_name: movieName,
            date: date,
            time: time
        };

        axios.post(Fetch_Booked_Seats_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            setBookedSeats(res.data.booked);
        })
        .catch(err => {
            // console.log(err);
            setBookedSeats([]);
        })

    };

    const refreshBookedSeats = () => {
        if (selectedMovie.movie_name && selectedMovie.date && selectedMovie.time) {
            handleBookedSeats(selectedMovie.movie_name, selectedMovie.date, selectedMovie.time);
        }
    };

    return (
        <>
            <ScreenLayoutWrapper>
                <div className="wrapper_inner">
                    {
                        noRegeisterShow &&
                        <div className="message">
                            <p>You haven't registered your theater yet. <Link to="/panel/theater-register">Register Now</Link></p>
                        </div>
                    }
                    {
                        RegeisteredShow &&
                        <div className="message">
                            <p>Your have already registered. Please wait for admin to respond. If you want to see your request status go to <Link to="/panel/theater-register">Theater Register</Link>.</p>
                        </div>
                    }

                    {
                        screensShow &&
                        <div className="screen_layout">
                            <div className="inner_top">
                                <div className="screen_select_sec">
                                    <div className="icon"><img src="/images/screen.png" alt="icon" /></div>
                                    <div className="dropdown_sec">
                                        <input type="text" value={selectedScreen ? selectedScreen.screen : ''} id="screen" required />
                                        <div className="dropdown_btn" onClick={handleScreenDropdown}>
                                            <p>{selectedScreen ? selectedScreen.screen : 'Select Screen'}</p>
                                            <span>Select Screen</span>
                                            <i className={`fa-solid fa-angle-down ${screenDropdown ? 'active' : ''}`}></i>
                                        </div>
                                        <div className={`dropdown ${screenDropdown ? 'active' : ''}`}>
                                            <ul>
                                                {
                                                    screens.map((screen, index) =>
                                                        <li key={index}
                                                            onClick={() => {
                                                                setSelectedScreen(screen);
                                                                setScreenDropdown(false);
                                                            }}
                                                        >{screen.screen}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="movie_details_sec">
                                    <div className="dropdown_sec">
                                        <input type="text" value={selectedMovieName} required />
                                        <div className="dropdown_btn" onClick={handleMoviesDropdown}>
                                            <p>{selectedMovieName}</p>
                                            <span>Select Movie</span>
                                            <i className={`fa-solid fa-angle-down ${moviesDropdown? 'active':''}`}></i>
                                        </div>
                                        <div className={`dropdown ${moviesDropdown? 'active':''}`}>
                                            <ul>
                                                {
                                                    movies && movies.map((movie, index) =>
                                                        <li key={index}
                                                            onClick={(e) => {
                                                                setSelectedMovieName(movie.movie_name)
                                                                setSelectedMovie({movie_name: movie.movie_name, language: movie.language, date: movie.date, time: movie.time})
                                                                setMoviesDropdown(false)
                                                                handleBookedSeats(movie.movie_name, movie.date, movie.time)
                                                            }}
                                                        >
                                                            <p>{movie.movie_name} ( <span><b>Language :</b> {movie.language}</span>, <span><b>Time :</b> {movie.time}</span> )</p>
                                                        </li>
                                                    )
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {
                                checkPublish ?
                                    <SeatLayout theater={theater} bookedSeats={bookedSeats} selectedMovie={selectedMovie} selectedScreen={selectedScreen} refreshBookedSeats={refreshBookedSeats} />
                                    :
                                    <div className="publish_message"><p>You have not published <span>{selectedScreen ? selectedScreen.screen : ''}</span> yet. <Link to="/panel/screen-settings">Publish Now</Link> </p></div>
                            }
                        </div>
                    }
                </div>
            </ScreenLayoutWrapper>
        </>
    );
}

export default ScreenLayouts;
