import { styled } from "@mui/material";


export const PanelLayout = styled('div')`
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;

    .left_side {
        position: relative;
        width: 300px;
        height: 100%;
    }

    .right_side {
        position: relative;
        width: calc(100% - 300px);
        height: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .top_bar {
            position: relative;
            width: 100%;
            height: 80px;
            border-bottom: 1px solid #E1E0EA;
        }

        .main_content {
            position: relative;
            width: 100%;
            height: calc(100% - 80px);
            overflow: hidden;
            padding: 60px 10px;
            padding-top: 40px;

            .content_inner {
                position: relative;
                width: 100%;
                height: 100%;
                padding: 0px 50px;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 6px;
                }

                &::-webkit-scrollbar-track {
                    background: transparent;
                    border-radius: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    background: #FC6736;
                    border-radius: 10px;
                }

                .inner_items {
                    position: relative;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
`;

export const ScreenLayoutWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .message {
            position: relative;
            width: 100%;
            padding-top: 20px;

            p {
                position: relative;
                font-size: 17px;
                color: #555;
                font-family: 'Lemonada', cursive;

                a {
                    font-weight: 500;
                    color: #02C0FF;
                    text-decoration: none;
                    cursor: pointer;
                    font-family: 'Lemonada', cursive;
                }
            }
        }

        .screen_layout {
            position: relative;
            width: 100%;
            height: 100%;

            .inner_top {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .screen_select_sec {
                    position: relative;
                    display: flex;
                    align-items: center;

                    .icon {
                        position: relative;
                        width: 75px;
                        height: 75px;
                        overflow: hidden;
                        border-radius: 50%;

                        img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }

                    .dropdown_sec {
                        position: relative;
                        margin-left: 25px;

                        input {
                            display: none;
                        }

                        .dropdown_btn {
                            position: relative;
                            width: 270px;
                            height: 55px;
                            border: 2px solid #E1E0EA;
                            border-radius: 6px;
                            padding: 5px 25px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;

                            p {
                                position: relative;
                                padding-right: 15px;
                                font-size: 16px;
                                line-height: 1.5;
                                color: #555;
                            }

                            span {
                                position: absolute;
                                top: 50%;
                                left: 25px;
                                font-size: 16px;
                                line-height: 1;
                                color: #555;
                                transform: translateY(-50%);
                                transition: 0.5s;
                            }

                            i {
                                font-size: 15px;
                                transition: 0.7s;

                                &.active {
                                    transform: rotate(-180deg);
                                    transition: 0.5s;
                                }
                            }
                        }

                        input:focus ~ .dropdown_btn,
                        input:valid ~ .dropdown_btn {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }

                        input:focus ~ .dropdown_btn span,
                        input:valid ~ .dropdown_btn span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }

                        .dropdown {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            z-index: 15;
                            background: #FFF;
                            border-radius: 5px;
                            box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.8s;

                            &.active {
                                max-height: 350px;
                                transition: all 0.8s;
                            }

                            ul {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                padding: 10px 10px;
                                max-height: 350px;
                                overflow-y: auto;
                                scrollbar-width: none;

                                li {
                                    position: relative;
                                    list-style: none;
                                    margin: 2px 0;
                                    padding: 13px 15px;
                                    color: #555;
                                    font-size: 14px;
                                    line-height: 1;
                                    border-radius: 4px;
                                    transition: all 0.5s;
                                    cursor: pointer;

                                    &:hover {
                                        background: #FC6736;
                                        color: #FFF;
                                        transition: all 0.5s;
                                    }
                                }
                            }
                        }
                    }
                }

                .movie_details_sec {
                    position: relative;
                    display: flex;
                    align-items: center;

                    .dropdown_sec {
                        position: relative;

                        input {
                            display: none;
                        }

                        .dropdown_btn {
                            position: relative;
                            width: 400px;
                            height: 55px;
                            border: 2px solid #E1E0EA;
                            border-radius: 6px;
                            padding: 5px 25px;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            cursor: pointer;

                            p {
                                position: relative;
                                padding-right: 15px;
                                font-size: 16px;
                                line-height: 1.5;
                                color: #555;
                            }

                            span {
                                position: absolute;
                                top: 50%;
                                left: 25px;
                                font-size: 16px;
                                line-height: 1;
                                color: #555;
                                transform: translateY(-50%);
                                transition: 0.5s;
                            }

                            i {
                                font-size: 15px;
                                transition: 0.7s;

                                &.active {
                                    transform: rotate(-180deg);
                                    transition: 0.5s;
                                }
                            }
                        }

                        input:focus ~ .dropdown_btn,
                        input:valid ~ .dropdown_btn {
                            border: 2px solid #FC6736;
                            transition: 0.5s;
                        }

                        input:focus ~ .dropdown_btn span,
                        input:valid ~ .dropdown_btn span {
                            top: 0%;
                            left: 15px;
                            font-size: 13px;
                            padding: 0px 8px;
                            background: #FFF;
                            color: #02C0FF;
                            font-weight: 500;
                            transition: 0.5s;
                        }

                        .dropdown {
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            z-index: 15;
                            background: #FFF;
                            border-radius: 5px;
                            box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                            max-height: 0;
                            overflow: hidden;
                            transition: all 0.8s;

                            &.active {
                                max-height: 350px;
                                transition: all 0.8s;
                            }

                            ul {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                padding: 10px 10px;
                                max-height: 350px;
                                overflow-y: auto;
                                scrollbar-width: none;

                                li {
                                    position: relative;
                                    list-style: none;
                                    margin: 2px 0;
                                    padding: 10px 15px;
                                    color: #333;
                                    font-size: 14px;
                                    line-height: 1;
                                    border-radius: 4px;
                                    transition: all 0.5s;
                                    cursor: pointer;

                                    &:hover {
                                        background: #FC6736;
                                        color: #FFF;
                                        transition: all 0.5s;
                                    }

                                    p {
                                        position: relative;
                                        width: 100%;
                                        font-size: 15px;
                                        line-height: 1.5;

                                        span {
                                            position: relative;

                                            b {
                                                font-weight: 500;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .publish_message {
                position: relative;
                margin-top: 50px;

                p {
                    position: relative;
                    font-family: 'Lemonada', cursive;
                    font-size: 16px;
                    color: #555;

                    span {
                        position: relative;
                        margin: 0px 8px;
                        font-family: 'Lemonada', cursive;
                        font-size: 17px;
                        color: #FC6736;
                        font-weight: 500;
                    }

                    a {
                        font-weight: 500;
                        color: #02C0FF;
                        text-decoration: none;
                        cursor: pointer;
                        font-family: 'Lemonada', cursive;
                    }
                }
            }

        }
    }
`;

export const SeatLayoutWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: calc(100% - 80px);
    padding-top: 30px;

    .layout_innner {
        position: relative;
        width: 100%;
        height: 100%;
        padding-top: 30px;
        
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: linear-gradient(to right, #fff, #E1E0EA, #fff);
        }

        .inner_content {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .seats_layout {
                position: relative;
                width: 100%;
                height: calc(100% - 55px);
                padding-bottom: 30px;

                .scroll_sec {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    overflow-y: auto;
                    scrollbar-width: none;

                    .swiper {
                        position: relative;
                        width: 100%;

                        .swiper-slide {
                            position: relative;
                            width: 100% !important;
                            min-height: 250px;

                            .screen_box {
                                position: relative;
                                width: 100%;
                                display: flex;
                                flex-direction: column;

                                .display_image {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    justify-content: center;
                                    margin-bottom: 70px;

                                    img {
                                        position: relative;
                                        width: 600px;
                                        display: flex;
                                    }
                                }

                                .seat_sections {
                                    position: relative;
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;

                                    .section {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        margin-bottom: 40px;

                                        &:last-of-type {
                                            margin-bottom: 0;
                                        }

                                        .sec_name {
                                            position: relative;
                                            width: 100%;
                                            padding-bottom: 15px;
                                            border-bottom: 1px solid #E1E0EA;
                                            display: flex;
                                            align-items: center;

                                            h5 {
                                                position: relative;
                                                font-family: 'Lemonada', cursive;
                                                font-size: 16px;
                                                line-height: 1;
                                                font-weight: 500;
                                                color: #02C0FF;
                                            }

                                            p {
                                                position: relative;
                                                margin-left: 12px;
                                                font-size: 15px;
                                                color: #555;
                                            }
                                        }

                                        .sec_seat_rows {
                                            position: relative;
                                            width: 100%;
                                            margin-top: 30px;
                                            display: flex;
                                            flex-direction: column-reverse;

                                            .seat_row {
                                                position: relative;
                                                width: 100%;
                                                padding: 0px 60px;
                                                margin-top: 15px;

                                                &:last-of-type {
                                                    margin-top: 0;
                                                }

                                                .seats {
                                                    position: relative;
                                                    width: 100%;
                                                    display: flex;
                                                    flex-direction: row-reverse;
                                                    align-items: center;

                                                    &.align_right {
                                                        justify-content: flex-start;
                                                    }

                                                    &.align_middle {
                                                        justify-content: center;
                                                    }

                                                    li {
                                                        position: relative;
                                                        list-style: none;
                                                        width: 40px;
                                                        cursor: pointer;
                                                        display: flex;
                                                        flex-direction: column;
                                                        align-items: center;
                                                        color: #555;

                                                        i {
                                                            position: relative;
                                                            font-size: 15px;
                                                        }

                                                        p {
                                                            position: relative;
                                                            margin-top: 5px;
                                                            font-size: 12px;
                                                            line-height: 1;
                                                        }

                                                        &.booked {
                                                            color: #ddd;
                                                            pointer-events: none;
                                                        }

                                                        &.selected {
                                                            color: #3FE44F;
                                                        }
                                                    }

                                                    &.gap_middle {
                                                        li.middle-seat {
                                                            margin-right: calc(100% / 31);
                                                        }
                                                    }

                                                    &.gap_left  {
                                                        li:nth-last-child(8) {
                                                            margin-right: calc(100% / 31);
                                                        }
                                                    }
                                                }

                                                .index {
                                                    position: absolute;
                                                    top: 30%;
                                                    right: 5px;
                                                    transform: translateY(-50%);
                                                    font-size: 17px;
                                                    font-weight: 500;
                                                    color: #FC6736;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .button_sec {
                position: relative;
                width: 100%;
                height: 55px;
                display: flex;
                justify-content: flex-end;

                a {
                    position: relative;
                    width: 220px;
                    height: 55px;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: 600;
                    overflow: hidden;
                    background: none;
                    border-radius: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border: 2px solid #3FE44F;

                    span {
                        position: relative;
                        z-index: 5;
                        color: #FFF;
                        transition: all 0.5s;
                        text-transform: uppercase;

                        i {
                            font-size: 18px;
                            transform: rotate(-45deg);
                            margin-right: 15px;
                        }
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: #3FE44F;
                        transform: scaleX(1);
                        transform-origin: left;
                        transition: transform 0.5s;
                    }

                    &:hover:before {
                        transform: scaleX(0);
                        transform-origin: right;
                        transition: transform 0.5s;
                    }

                    &:hover span {
                        color: #3FE44F;
                        transition: all 0.4s;
                    }
                }
            }
        }
    }
`;