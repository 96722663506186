import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Login from "../Pages/Login";

import PaneLayout from "../Layouts/Panel-Layout";
import Dashboard from "../Pages/Dashboard";
import Screens from "../Pages/Screens";
import ScreenLayouts from "../Pages/ScreenLayouts";
import ScreenSetting from "../Pages/ScreenSettings";
import AddMovies from "../Pages/AddMovie";
import AllMovies from "../Pages/AllMovies";
import TodayShowing from "../Pages/TodayShowing";
import Review from "../Pages/Reviews";
import RequestMovie from "../Pages/RequestMovie";
import Register from "../Pages/TheaterRegister";




function Router() {
    return(
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login/>} />
                    <Route path="panel" element={<PaneLayout />}>
                        <Route path="" element={<Navigate to="dashboard" />} />
                        <Route path="dashboard" element={<Dashboard />}  />
                        <Route path="theater-register" element={<Register />}  />
                        <Route path="screens" element={<Screens />}  />
                        <Route path="seat-layouts" element={<ScreenLayouts />}  />
                        <Route path="screen-settings" element={<ScreenSetting />}  />
                        <Route path="add-movies" element={<AddMovies />}  />
                        <Route path="all-movies" element={<AllMovies />}  />
                        <Route path="today-showing" element={<TodayShowing />}  />
                        <Route path="request-movie" element={<RequestMovie />}  />
                        <Route path="reviews" element={<Review />}  />
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}



export default Router;