import { useEffect, useState } from "react";
import Screen from "../Components/Screen";
import { ScreensWrapper } from "../Styles/Screens-Style";
import axios from "axios";
import { Fetch_Screens_URL } from "../API/Api";
import { Link } from "react-router-dom";



function Screens() {

    // const screens = ['Screen 1','Screen 2','Screen 3'];
    const [screen, setScreen] = useState('');
    const [noRegeisterShow, setNoRegeisterShow] = useState(true);
    const [RegeisteredShow, setRegeisteredShow] = useState(false);
    const [screenDropdown, setScreenDropdown] = useState(false);
    const [screensShow, setScreensShow] = useState(false);
    const [screens, setScreens] = useState([]);
    const [selectedScreen, setSelectedScreen] = useState([]);
    const [reload, setReload] = useState(false);
    const [checkPublish, setCheckPublish] = useState(false);


    useEffect(() => {

        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Screens_URL}?user=${user}`)
        .then(res => {
            let status = res.data.status;

            if(status == "202"){
                setNoRegeisterShow(true);
                setRegeisteredShow(false);
            } else if(status == "200"){
                setNoRegeisterShow(false);
                setRegeisteredShow(false);
                setScreensShow(true);
                setSelectedScreen(res.data.screens[0]);
                setScreens(res.data.screens);
                setReload(false);
                console.log(res.data.screens);
            }

        })
        .catch(err => {
            console.log(err);
            setNoRegeisterShow(false);
            setRegeisteredShow(true);
        })

    }, [reload]);


    useEffect(() => {

        if(selectedScreen.prices == null){
            setCheckPublish(false);
        } else {
            setCheckPublish(true);
        }

    }, [selectedScreen]);


    const handleScreens = () => {
        setScreenDropdown(!screenDropdown);
    };

    return(
        <>
            <ScreensWrapper>
                {
                    noRegeisterShow && 
                    <div className="message">
                        <p>You haven't registered your theater yet. <Link to="/panel/theater-register">Register Now</Link></p>
                    </div>
                }
                {
                    RegeisteredShow && 
                    <div className="message">
                        <p>Your have already registered. Please wait for admin to respond. If you want to see your request status go to <Link to="/panel/theater-register">Theater Register</Link>.</p>
                    </div>
                }

                {
                    screensShow &&
                    <div className="screen_details">
                        <div className="top_sec">
                            <div className="screen_select_sec">
                                <div className="icon"><img src="/images/screen.png" alt="icon" /></div>
                                <div className="dropdown_sec">
                                    <input type="text" value={selectedScreen.screen} id="screen" required />
                                    <div className="dropdown_btn" onClick={handleScreens}>
                                        <p>{selectedScreen.screen}</p>
                                        <span>Select Screen</span>
                                        <i className={`fa-solid fa-angle-down ${screenDropdown? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${screenDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                screens.map((screen, index) => 
                                                    <li key={index}
                                                        onClick={() => {
                                                            setScreen(screen)
                                                            setSelectedScreen(screen)
                                                            setScreenDropdown(false)
                                                        }}
                                                    >{screen.screen}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="box">
                                <div className="box_inner">
                                    <div className="inner_item">
                                        <h4>Total Seat Capacity</h4>
                                        <p>{selectedScreen.capacity}</p>
                                    </div>
                                    <div className="icon">
                                        <i className="fa-solid fa-couch"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="details_sec">
                            {
                                checkPublish ?
                                <Screen selectedScreen={selectedScreen} />
                                :
                                <div className="publish_message"><p>You have not published <span>{selectedScreen.screen}</span> yet. <Link to="/panel/screen-settings">Publish Now</Link> </p></div>
                            }
                        </div>
                    </div>
                }

            </ScreensWrapper>
        </>
    );
}


export default Screens;