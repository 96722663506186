import React, { useState } from 'react';
import { DashboardWrapper } from "../Styles/Dashboard-Style";
import { Link } from 'react-router-dom';
import BookingData from '../Charts/WeeklyBooking-Chart';
import TicketData from '../Charts/TicketsData-Chart';
import axios from "axios";






function Dashboard() {

    return(
        <>
            <DashboardWrapper>
                <div className="dashboard_inner">
                    <div className="dashboard_box_sec">
                        <Link to="/panel/screens" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Total No. Screens</h4>
                                    <p>2</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-clapperboard"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/screens" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Total Ticket Booked</h4>
                                    <p>255</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-ticket"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/all-movies" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>This Week</h4>
                                    <p>155</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-film"></i>
                                </div>
                            </div>
                        </Link>
                        <Link to="/panel/today-showing" className="box">
                            <div className="box_inner">
                                <div className="inner_item">
                                    <h4>Today Showing</h4>
                                    <p>55</p>
                                </div>
                                <div className="icon">
                                    <i className="fa-solid fa-video"></i>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="superadmin_graph_sec">
                        <div className="user_graph">
                            <h4>Booking Data ( Weekly )</h4>
                            <div className="graph_box">
                                <BookingData />
                            </div>
                        </div>
                        <div className="movie_graph">
                            <h4>Bookings ( Online & Offline )</h4>
                            <div className="graph_box">
                                <TicketData />
                            </div>
                        </div>
                    </div>
                </div>
            </DashboardWrapper>
        </>
    );

}


export default Dashboard;